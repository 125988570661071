export default function Message() {
  return (
    <section>
      <div className="message-container">
        {/* VC */}
        <div className="placement-officer">
          <div className="message-section">
            {/* Image Section */}
            <div className="image-section">
              <div className="image">
                <img
                  src={require("../images/about-image/message/vc.jpg")}
                  alt=""
                />
              </div>
              <div className="info">
                <h2>Prof. Mohammad Shakeel</h2>
                <em>Hon'ble Vice Chancellor</em>
                {/* Social icons */}
                <div className="contact">
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:vc@jmi.ac.in">vc@jmi.ac.in</a>
                  </span>
                </div>
              </div>
            </div>
            {/* All message section */}
            <div className="message">
              <p>Dear Recruiters,</p>
              <p>
                Jamia Millia Islamia, a renowned Central University established
                in 1920, has evolved into a multifaceted educational
                institution, offering a comprehensive range of programs from
                schooling to post-doctoral studies. Living up to the vision of
                its founding fathers, Jamia strives to create an inclusive,
                equitable, and just human universe, fostering innovation and
                illuminating ideas. In its 100th year, Jamia Millia Islamia has
                been recognized as one of the best Indian Central Universities
                by the Ministry of Education, and ranked 3rd among Central
                Universities by the National Institutional Ranking Framework
                (NIRF) 2022. Jamia’s excellence spans diverse disciplines,
                including Computer Science, Biosciences, Physics, Chemistry,
                Law, Dentistry, Engineering, Humanities, Languages, and Mass
                Communication, with the upcoming launch of a Medical program.
                Proudly accredited by NAAC under the A++ category, Jamia Millia
                Islamia continues to set unparalleled benchmarks, solidifying
                its position as a beacon of higher education in India.
              </p>
              <p>
                The university was established to impart knowledge to human
                beings to develop a human society with wisdom. However, in the
                era of ICT/Internet, the role of universities has diversified
                substantially. Now, universities have to fulfill their
                commitment in the fast-changing environment of this millennium.
                Therefore, placement records and placement prospects have become
                one of the more important parameters for the success of
                universities and their departments, which need to strengthen
                dynamically.
              </p>
              <p>
                I feel proud of the Department of Computer Science, which has
                been conscious and sensitive to these matters. It has not only
                restructured its courses thoroughly to incorporate the latest
                and the best but also appointed a Placement Committee led by a
                Professor to improve placement prospects for the current and
                forthcoming batches. I am also happy to recognize Dr. Suraiya
                Jabin’s dynamic leadership, as she is directly involved in
                placement-related activities. The university-level placement
                team led by Dr. Rahela Farooqi is also striving hard for the
                same goal. Therefore, I have no hesitation in stating that it
                will bring marvelous results for the benefit of our professional
                students of the Master of Computer Applications program.
              </p>
              <p>
                I invite all concerned industries to help us strengthen our
                campus placement efforts by selecting our students as
                professionals, thereby giving them opportunities to contribute
                positively towards their industrial goals and at the same time
                allowing them to grow. I wish them success in their future
                endeavors.
              </p>
            </div>
          </div>
        </div>
        {/*Dean */}
        <div className="placement-officer">
          <div className="message-section">
            {/* Image Section */}
            <div className="image-section">
              <div className="image">
                <img
                  src={require("../images/about-image/message/dean.jpg")}
                  alt=""
                />
              </div>
              <div className="info">
                <h2>Dr. Saeed Uddin</h2>
                <em>Dean, Faculty of Sciences</em>
                {/* Social icons */}
                <div className="contact">
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:suddin@jmi.ac.in">suddin@jmi.ac.in</a>
                  </span>
                </div>
              </div>
            </div>
            {/* All message section */}
            <div className="message">
              <p>
                The era of globalization, coupled with the advent of information
                technology (IT) and the impetus of the internet, has brought up
                a sea change in the corporate governance and thinking process of
                the human mind, especially in the current millennium. I am
                pleased to share that the Department Of Computer Science under
                the Faculty of Natural Science produces quality professionals in
                the field of IT. In addition to a 2 years post-graduate program,
                Master Of Computer Application (MCA), the Department is
                successfully running a PGDCA and a post-graduate professional
                degree course, M.Sc. (Bioinformatics) through a well-designed
                fundamental industry-oriented curriculum.
              </p>
              <p>
                I am sure that the dedication and commitment of the student to
                the skills developed with these courses, would contribute highly
                towards national development by placing themselves in the
                various industries and educational institutions, both
                domestically and internationally. The mission of producing able
                and competent IT and Bioinformatics professionals in the
                department is being achieved due to the involvement of highly
                dedicated and experienced faculty members. I hope they will
                continue to produce worthy professionals to fulfil the
                industrial and educational needs of the nation.
              </p>
              <p>
                With this objective, I certainly would like to invite all
                esteemed and relevant organizations and companies to induct our
                students as competent professionals that would eventually enable
                them to make a profound impact in this industry as well as at a
                global level.
              </p>
            </div>
          </div>
        </div>
        {/* HOD  */}
        <div className="hod">
          <div className="message-section">
            <div className="image-section">
              <div className="image">
                <img
                  src={require("../images/about-image/message/hod2.jpg")}
                  alt=""
                />
              </div>
              <div className="info">
                <h2>Dr. Monica Mehrotra</h2>
                <em>Professor & HOD</em>
                {/* Social icons */}
                <div className="contact">
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:mmehrotra@jmi.ac.in">mmehrotra@jmi.ac.in</a>
                  </span>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                    <a href="tel:9818846513">+91-9818846513</a>
                  </span>
                </div>
              </div>
            </div>
            {/* All message section */}
            <div className="message">
              <p>
                The Indian economy is considered one of the fastest-growing and
                emerging economies in recent times. The major reason behind the
                same is the strength of its human resource. Being the second
                most populated country in the world, India can reach greater
                heights if its human resource is utilized appropriately, both by
                the government as well as individuals and institutions. And one
                such measure is the provision of qualitative education of the
                highest standard from any measurable angle.
              </p>
              <p>
                Institutions of higher learning such as Jamia Millia Islamia, a
                Central University, are committed to the task of bridging this
                gap. In this endeavour, the Department of Computer Science, JMI
                has enriched its programs through a complete overhauling of
                course structure, taking into account the contemporary
                requirements of the industry.
              </p>
              <p>
                We have a strong placement team with an experienced senior
                professor at the helm, to guide the talented youth by providing
                direction to attain the right placements which will smoothen
                life’s journey for them as well as future generations. The
                Department boasts of availability of the state of the art
                infrastructure as well as highly motivated and qualified faculty
                members and staff to guide the youth, which in turn has also
                been reflected in the overall performance of the students.
              </p>
              <p>
                I, personally, take pleasure in inviting industry to take the
                opportunity of availing the best of the brains for an individual
                as well the corporate world and, thereby, being a part of
                national development.
              </p>
            </div>
          </div>
        </div>
        {/* UPC head */}
        <div className="headUPC">
          <div className="message-section">
            <div className="image-section">
              <div className="image">
                <img
                  src={require("../images/about-image/message/HeadUpc.jpg")}
                  alt=""
                />
              </div>
              <div className="info">
                <h2>Dr. Rahela Farooqi</h2>
                <em>Honorary Director, University Placement Cell</em>
                {/* Social icons */}
                <div className="contact">
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:rfarooqi@jmi.ac.in">rfarooqi@jmi.ac.in</a>
                  </span>
                  <span>
                    <i className="fa-solid fa-phone"></i>
                    <a href="tel:9910448554">+91-9818846513</a>
                  </span>
                </div>
              </div>
            </div>
            {/* All message section */}
            <div className="message">
              <p>
                Jamia Millia Islamia, New Delhi, is a leading University of
                higher education in India with a proven track record and
                performance. It is our constant endeavour, to provide knowledge
                based services to satisfy the needs of society and industries.
                The objective of these efforts is to develop potential
                professionals for the development and growth of our nation.
              </p>
              <p>
                All the students recruited from the Department of Computer
                Science have proved to be a great asset to the
                companies/organization they joined in the recent past. We have
                witnessed these students achieve greater heights in their life
                from trainees to CEOs to Entrepreneurs. I am certain that our
                present students would maintain the same level of performance
                and prove to be a great asset to their recruiting
                organization/companies.
              </p>
              <p>
                Our placement office is well equipped with the facilities that
                are required for the smooth conduct of Training and Placement
                recruitment activities. I am sure that our students will find it
                useful in securing the placement of their choice. I congratulate
                the placement coordinator of the department for their efforts
                that makes the campus placement drives smooth and successful.
              </p>
              <p>
                I wish the On-Campus recruitment program a great success and
                shall always look forward to mutually beneficial relationships
                with the recruiters of our students.
              </p>
            </div>
          </div>
        </div>
        {/* TPC */}
        <div className="placement-officer">
          <div className="message-section">
            {/* Image Section */}
            <div className="image-section">
              <div className="image">
                <img
                  src={require("../images/about-image/message/tpc.jpg")}
                  alt=""
                />
              </div>
              <div className="info">
                <h2>Dr. Suraiya Jabin</h2>
                <em>Teacher Placement Coordinator</em>
                {/* Social icons */}
                <div className="contact">
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:sjabin@jmi.ac.in">sjabin@jmi.ac.in</a>
                  </span>
                </div>
              </div>
            </div>
            {/* All message section */}
            <div className="message">
              <p>
                The placement committee of our Department has been playing an
                active role in shaping the career of the students by bridging
                the gap between industry requirements and a student’s career.
                Our past records are extremely good but the best is yet to come.
                The Programmes/Courses run by our department are job-oriented
                which not only impart higher knowledge but also make one
                competitive at the global level.
              </p>
              <p>
                As India is already one of the leaders in producing skilled
                manpower in the software industry, our Department adds one more
                feather to this cap. To keep abreast with the latest Development
                in IT and Bio-informatics, the Department organizes workshops
                and extension lectures to boost the knowledge as well as the
                morale of the students. Here students are given independent
                projects to work on to explore their skills, and abilities and
                also act as tools for self judgement. A joint smooth programme
                is made with the central TPO of the University and the Placement
                Committee of the Department. The Department also organizes
                motivational lectures for the student on placement from time to
                time. This gives the student an idea of how to face an interview
                and other selection tests in the corporate sector by which we
                can produce better results.
              </p>
              <p>
                The Placement Programme of the Department normally starts with
                the visits to industries just after the end of even semester
                examination. However, this year, considering the current
                situation, I welcome you to participate in our Virtual Campus
                Placement Programme. The placement brochure contains the student
                profile details. I request you to kindly let us know your plan
                to visit our Department(DCS). Looking forward to a fruitful
                association.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
